import React, { useEffect, useState } from 'react';
import { AsyncTypeahead, TypeaheadRef } from 'react-bootstrap-typeahead';
import { Option } from 'react-bootstrap-typeahead/types/types';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { ICollection } from '../../types/collections';
import CollectionsService from "../../services/Collections/CollectionsService";

interface IProp {
    name: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    filter?: (option: Option) => boolean;
    typeaheadRef?: React.RefObject<TypeaheadRef>;
}

const CollectionLookup: React.FC<IProp> = ({ name, value, onChange, filter, typeaheadRef }) => {
    const defaultFilter = () => true;

    const [isLoading, setIsLoading] = useState(true);
    const [selectedCollection, setSelectedCollection] = useState<ICollection>();
    const [options, setOptions] = useState<Option[]>([]);

    const setCollectionById = (collectionId: string) => {
        CollectionsService.getCollection(collectionId).then((res) => {
            if (res) {
                setOptions([res]);

                // This is required to pre-populate lookup control
                setSelectedCollection(res);
            } else {
                setSelectedCollection(undefined);
            }

            setIsLoading(false);
        });
    }

    useEffect(() => {
        if (value) {
            setCollectionById(value);
        } else {
            setSelectedCollection(undefined);
            setIsLoading(false);
        }
    }, [value]);

    const extractCollectionId = (input: string | null) => {
        if (!input) {
            return null;
        }

        // Extract the collection ID from the URL
        const match = input.match(/collections\/(col-\w+)/);

        if (match && match[1]) {
            return match[1];
        }

        // Return null if no collections found
        return null;
    }

    const handleOnChange = (selected: Option[]) => {
        const event = {
            target: {
                name: name,
                value: selected.length ? (selected[0] as ICollection)?.id : null,
            },
        };

        setSelectedCollection(selected.length ? (selected[0] as ICollection) : undefined);

        // Small hack to make it compatible with existing code. It's good to refactor it in the future.
        onChange(event as unknown as React.ChangeEvent<HTMLInputElement>);
    }

    const handleSearch = (query: string) => {
        // If current input is a full collection url, load the collection by id
        const collectionId = extractCollectionId(query);
        if (collectionId) {
            setCollectionById(collectionId);
        } else {
            setIsLoading(true);

            CollectionsService.getCollections(0, 30, query)
            .then(items => {
                setOptions(items);
                setIsLoading(false);
            });
        }
    };

    return (
        <AsyncTypeahead
            clearButton
            ref={typeaheadRef}
            style={{ width: "100%" }}
            selected={selectedCollection ? [selectedCollection] : []}
            filterBy={filter ?? defaultFilter}
            id={name}
            isLoading={isLoading}
            labelKey="name"
            minLength={1}
            onSearch={handleSearch}
            onChange={handleOnChange}
            options={options}
            placeholder="Search for a collection..."
            renderMenuItemChildren={(option) => (
                <>
                    <img
                        alt={(option as ICollection).name}
                        src={(option as ICollection).iconUrl}
                        style={{
                        height: '24px',
                        marginRight: '10px',
                        width: '24px',
                        }}
                    />
                    <span>{(option as ICollection).name}</span>
                </>
            )}
        />
    );
}

export type LookupRef = TypeaheadRef;
export default CollectionLookup;