import React from "react";
import { Alert, Button, Form, Table } from "react-bootstrap";
import { BiPlus, BiTrash } from "react-icons/bi";
import { IObject } from "../../../types/objects";
import MapObjectLookup, { LookupRef } from "../../common/MapObjectLookup";
import { ICouponObjectConditions } from "../../../types/coupons";

interface IProps {
    objects: IObject[] | null;
    objectConditions: ICouponObjectConditions[] | null;
    emptyPlaceholder?: string;
    onObjectAdded?: (objectId: string, numRequiredCollects: number) => void;
    onObjectRemoved?: (objectId: string) => void;
}
function ObjectsListWithAddRemove({ objects, objectConditions, emptyPlaceholder, onObjectAdded, onObjectRemoved }: IProps) {
    const [objectId, setObjectId] = React.useState<string>("" as string);
    const [numRequiredCollects, setNumRequiredCollects] = React.useState<number>(1);
    const typeahead = React.useRef<LookupRef>(null);
    const handleAddObject = () => {
        if (onObjectAdded)
            onObjectAdded(objectId, numRequiredCollects);

        setObjectId("");
        setNumRequiredCollects(1);
        typeahead.current?.clear();
    }

    const handleRemoveObject = (objectId: string) => {
        if (onObjectRemoved)
            onObjectRemoved(objectId);
    }
    if (!objects)
        return <div></div>;

    return (
        <div>
            <Alert variant="warning" >
                Changes to this section will be applied immediately.
            </Alert>

            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th></th>
                        <th>Title</th>
                        <th>Location</th>
                        <th>Conditions</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {objects.map((object, index) => {
                        const requiredCollects = objectConditions?.find(c => c.mapObjectId === object.id)?.numRequiredCollects ?? 1;

                        return (
                            <tr key={index} style={{verticalAlign: "middle"}}>
                                <td><img src={object.iconUrl} alt={object.internalName} className="rounded" style={{ width: "40px" }} /></td>
                                <td>
                                    <a target="_blank" rel="noreferrer" href={`${window.location.origin}/objects/${object.id}`}>
                                        {object.internalName}
                                    </a>
                                </td>
                                <td>{[object.country?.displayName, object.region?.displayName, object.place?.displayName].filter(x=>x!=null).join(", ")}</td>
                                <td>{`Collect the trophy ${ requiredCollects > 1 ? `at least ${requiredCollects} times` : 'once' }`}</td>
                                <td width="50px">
                                    <Button variant="danger" size="sm" onClick={e => handleRemoveObject(object.id)}>
                                        <BiTrash className="mb-1" />
                                    </Button>
                                </td>
                            </tr>
                    )})}
                </tbody>
            </Table>

            {objects.length === 0 && <div>{emptyPlaceholder}</div>}

            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Object</th>
                        <th>Number of required collects</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={{verticalAlign: "middle"}}>
                        <td>
                            <MapObjectLookup
                                name="select-object"
                                value={objectId || ''}
                                onChange={e => setObjectId(e.target.value)}
                                typeaheadRef={typeahead}
                                filter={(option) => !objects.some(o => o.id === (option as any).id)}
                            />
                        </td>
                        <td width="250px">
                            <Form.Control
                                type="number"
                                min="1"
                                name="numRequiredCollects"
                                value={numRequiredCollects}
                                onChange={e => setNumRequiredCollects(parseInt(e.target.value))}
                            />
                        </td>
                        <td width="50px">
                            <Button variant="primary" size="sm" className="me-2" onClick={handleAddObject}><BiPlus className="mb-1" /></Button>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    );
}

export default ObjectsListWithAddRemove;