import constants from "../../constants";
import {
  ICollection,
  ICollectionDetails,
  ICollectionEditor,
} from "../../types/collections";
import axios from "axios";
import HelperService from "../HelperService";
import CommonService from "../CommonService";
import { IAttachmentType, IMimeType } from "../../types/common";

interface IGetParams {
  skip: number;
  limit: number;
  searchText?: string;
}

let controller: AbortController | null;

class CollectionsService {
  getCollections = (skip: number, limit: number, text: string) => {
    if (controller) {
      controller.abort();
    }
    controller = new AbortController();
    const url = `${constants.API_ADMIN_COLLECTIONS}/GetCollections`;
    const params: IGetParams = { skip, limit };
    if (text.length) {
      params.searchText = text;
    }

    return axios.get(url, { params, signal: controller.signal }).then((res) => {
      let data: ICollection[];
      data = res.data.map((d: any): ICollection[] => ({
        ...d,
        iconUrl: HelperService.getAssetUrl(d.assetsBaseUrl, d.iconFile),
      }));
      controller = null;
      return data;
    });
  };

  getCollection = (id: string): Promise<ICollectionDetails> => {
    const url = `${constants.API_ADMIN_COLLECTIONS}/Get?id=${id}`;
    return axios.get(url).then((res) => res.data);
  };

  archiveCollection = async (id: string) => {
    const url = `${constants.API_ADMIN_COLLECTIONS}/Archive?id=${id}`;
    try {
      await axios.post(url).then();
      return true;
    } catch {
      return false;
    }
  };

  publishCollection = async (id: string) => {
    const url = `${constants.API_ADMIN_COLLECTIONS}/Publish?id=${id}`;
    try {
      await axios.post(url).then();
      return true;
    } catch {
      return false;
    }
  };

  deleteCollection = async (id: string) => {
    const url = `${constants.API_ADMIN_COLLECTIONS}/Delete?id=${id}`;
    try {
      await axios.delete(url).then();
      return true;
    } catch {
      return false;
    }
  };

  editorCreateCollection = async (data: ICollectionEditor) => {
    const newData = this.editorDataToRequestData(data);
    try {
      const { id } = await this.createCollection(newData);
      await this.editorUpdateCollection(id, data);
      return id;
    } catch {
      return false;
    }
  };

  editorUpdateCollection = async (id: string, data: ICollectionEditor) => {
    const getUploadUrl = `${constants.API_ADMIN_COLLECTIONS}/getFileUploadUrl`;

    try {
      const extraPhotos: string[] = [];
      for (const image of data.extraPhotos) {
        if (image.file) {
          const uploadResult = await CommonService.uploadFile(getUploadUrl, image.file, {
            targetId: id,
            attachmentType: IAttachmentType.PublicPhoto,
            mimeType: image.file.type,
          });
          extraPhotos.push(uploadResult.fileName);
        } else if (image.name) extraPhotos.push(image.name);
      }

      const adminPictures: string[] = [];
      for (const image of data.adminPictures) {
        if (image.file) {
          const uploadResult = await CommonService.uploadFile(getUploadUrl, image.file, {
              targetId: id,
              attachmentType: IAttachmentType.AdminPhoto,
              mimeType: image.file.type,
            });
          adminPictures.push(uploadResult.fileName);
        } else if (image.name) adminPictures.push(image.name);
      }

      let imageUpdate = false;
      if (data.imageFile?.file) {
        await CommonService.uploadFile(getUploadUrl, data.imageFile.file, {
          targetId: id,
          attachmentType: IAttachmentType.ModelImage,
          mimeType: data.imageFile.file.type,
        });
        const iconFile = await HelperService.dataUrlToFile(
          (await HelperService.resizeDataURL(
            data.imageFile.url,
            150,
            150
          )) as string,
          "icon.png",
          IMimeType.imagePng
        );
        await CommonService.uploadFile(getUploadUrl, iconFile, {
          targetId: id,
          attachmentType: IAttachmentType.ModelIcon,
          mimeType: iconFile.type,
        });
        imageUpdate = true;
      }

      const updateData = {
        ...this.editorDataToRequestData(data),
        id: id,
        extraPhotos,
        adminPictures,
      };
      await this.updateCollection(updateData);
      if (imageUpdate) await HelperService.delay(3000);
      return id;
    } catch {
      return false;
    }
  };

  updateCollection = async (data: any) => {
    const url = `${constants.API_ADMIN_COLLECTIONS}/Update`;
    return axios.post(url, data).then((res) => res.data);
  };
  createCollection = (data: any) => {
    const url = `${constants.API_ADMIN_COLLECTIONS}/Create`;
    return axios.post(url, data).then((res) => res.data);
  };

  editorDataToRequestData = (data: ICollectionEditor) => {
    return {
      adminNotes: data.adminNotes,
      details: data.details,
      name: data.name,
      link: {
        url: data.link,
        title: data.linkTitle,
      },
      tags: data.tags,
      countryId: data.country ? data.country.id : null,
      regionId: data.region ? data.region.id : null,
      placeId: data.place ? data.place.id : null,
      couponCampaignIds: data.couponCampaignIds,
    };
  };
}
export default new CollectionsService();
